.videoContainer {
    overflow: hidden;
    position: relative;
    width:100%;
    -webkit-box-shadow: 0px 0px 14px 0px black;
    -moz-box-shadow: 0px 0px 14px 0px black;
    box-shadow: 0px 0px 14px 0px black;
}

.videoContainer::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}