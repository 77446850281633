#WhyUsContainer{
    .WhyUsHeading{
        color: #FF8000;
        font-size: 30px;
        margin-bottom: 20px;
        text-shadow: 0 0 30px rgb(255 128 0 / 70%);
        font-weight: 800;
    }
    .WhyUsParagraph{
        color: #D8D8D8;
        font-weight: 300;
    }
}

#container{
    padding: 150px 0 50px 0;

    #copyright{
        p{
            font-weight: 300; 
            font-size: 10px;
        }
    }

    #links{
        ul{
            list-style-type: none; 
            padding: 0; 
            padding-top: 20px;
            li {
                padding-bottom: 5px;
                    a{
                        text-decoration: none;
                        color: white;
                    }
                }
        }
    }

    #versions{
        ul{
            list-style-type: none; 
            padding: 0; 
            padding-top: 20px;
            li {
                padding-bottom: 5px;
                    a{
                        text-decoration: none;
                        color: white;
                    }
                }
        }
    }

    #gamemodes{
        ul{
            list-style-type: none; 
            padding: 0; 
            padding-top: 20px;
            li {
            padding-bottom: 5px;
                a{
                    text-decoration: none;
                    color: white;
                }
            }
        }
    }
}

