#container{

    .title{
        font-size: 36px;
        font-weight: 800;
    }

    .column {

        .videoWrapper{
            background-color: #000;
            display: flex;
            position: relative;
            opacity: 0.7;
            transition: all 300ms;
            cursor: pointer;
            border: 1px solid rgb(209, 114, 19);

            video{
                width: 100%;
            }

            .gamemodeName{
                background-color: #00000085;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 25%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding-inline: 30px;

                div{
                    color: #fff;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            &:hover{
                opacity: 1;
                transform: translateZ(0) scale(1.1);
            }
        }
    }
} 