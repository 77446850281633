#navWrapper {
  .pulse {
    animation: pulse 2s infinite;
    background-color: green;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }

  li:hover {
    cursor: pointer;
  }

  li .underline {
    width: 100%;
    background-color: transparent;
    height: 3px;
    border-radius: 10;
  }

  li:hover .underline {
    background-color: rgb(255, 128, 0);
  }

  li .icon {
    visibility: hidden;
  }

  li:hover .icon {
    visibility: visible;
  }

  li .underlineActive {
    background-color: rgb(255, 128, 0);
    width: 100%;
    height: 3px;
    border-radius: 10;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7)
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 255, 0, 0);
  }

  100% {
    transform: scale(0.9);
  }
}