@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

body{
  background-image: url('./assets/img/bg.jpeg');
  background-repeat:  no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

*{
  font-family: 'Poppins';
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #120501;
}

::-webkit-scrollbar-thumb {
  background: #d17213;
  border-radius: 3px;
}

.blackhead-image-skin-hero-es{
  width: 500px;
}

@media screen and (max-width: 600px) {
  .blackhead-image-skin-hero-es{
    width: 200px;
  }
}

.backdrop{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.navbar-modal{
  width: min(400px, 90%);
  height: 400px;
  background-color: #000;
  margin: auto;
  border-radius: 20px;
}


.dynmap{
  border-radius: 50px;
    box-shadow: 0 0 30px rgb(255 128 0 / 20%);
    border: 2px solid rgb(255, 128, 0);
}


.fancy {
  line-height: 0.5;
  text-align: center;
}

.fancy span {
  display: inline-block;
  position: relative;  
}

.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 1px;
  border: 2px solid rgb(255, 128, 0);
  border-radius: 2px;
  top: 50%;
  width: 50px;
}
.fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.fancy span:after {
  left: 100%;
  margin-left: 15px;
}

.bw-23c{
  background-image: url('./assets/img/gw.png');
}


  .slide{
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
  }

    .swiper-button-next, .swiper-button-prev{
      top: 45% !important;
      color: #d17213 !important
    }

    .swiper-button-next, .swiper-rtl .swiper-button-prev{
      right: 0px !important;
    }

    .swiper-button-prev, .swiper-rtl .swiper-button-next{
      left: 0px !important;
    }

    .swiper-pagination-bullet{
      background-color: #d17213 !important;
      width: 16px !important;
      height: 5px !important;
      border-radius: 5px !important;
      transition: all 100ms;
    }

    .swiper-pagination-bullet-active{
      width: 26px !important;
    }
