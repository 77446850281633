#heroContentContainer{
    h1{
        color: #fff;
        font-weight: 800;
    }
    p{
        color: #D8D8D8;
        font-weight: 300;
        font-size: 20px;
    }
}