#section{
    #title{
        font-size: 32px;
        color: "#fff"; 
        font-weight: 800;
        text-align: center;
    }
    #paragraph{
        text-align: center;
        color: "#D8D8D8";
        font-weight: 300; 
        font-size: 20px;
        padding-bottom: 30px;
    }
}